<template>
  <div v-if="item" :ref="`infodrop_${_uid}`" :id="`block_${item.id || _uid}`">
    <div class="info-item__above">
      <div
        class="info-item__above-title"
        v-html="item.content_above"
        v-if="item.content_above"
      ></div>
    </div>
    <div class="info-item" v-if="item.content" :class="{ active: openInner }">
      <div class="info-item__head" @click="toggleArrow">
        <div class="info-item__head-title">
          <div class="title-container" v-html="item.title">
            <span v-if="item.tag">{{ item.tag }}</span>
          </div>
        </div>
        <div class="info-item__head-arrow ml-4">
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 1.25L8.75 8.75L1.25 1.25"
              stroke="#3C4242"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
      <!-- v-html="item.content" -->
      <div class="info-item__inner" v-html="item.content"></div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/detail_content.scss";
import Vue from "vue";
import SimpleCarousel from "@/components/pageComponents/SimpleCarousel.vue";
import RsviTabs from "@/components/interactiveTests/rsvi-tabs.vue";
import Calculator from "@/components/interactiveTests/calculator.vue";
export default {
  name: "InfoDrop",
  props: {
    item: Object,
    active: Boolean,
    color: String,
  },
  data: () => ({
    openInner: false,
  }),
  methods: {
    toggleArrow() {
      this.openInner = !this.openInner;
      if (this.openInner) {
        this.$emit("openInner");
      }
    },
  },
  async mounted() {
    const vm = this;
    if (
      this.$route?.params?.block_id &&
      this.item.id === this.$route?.params?.block_id
    ) {
      this.openInner = true;
      this.$refs[`infodrop_${this._uid}`].scrollIntoView({
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      if (
        this.$route &&
        this.$route.hash &&
        (this.$el.querySelector(this.$route.hash) ||
          this.$el.id === this.$route.hash.replace("#", ""))
      ) {
         const y = this.$el.getBoundingClientRect().top + window.scrollY;
        this.openInner = true;
        window.scrollTo({
          behavior: "smooth",
          top: y - 150,
        });
      }
    }, 1000);

    if (this.active) {
      this.openInner = true;
    }

    // if (
    //   this.$refs[`infodrop_${this._uid}`] &&
    //   this.$refs[`infodrop_${this._uid}`].querySelector(".full-screen__trigger")
    // ) {
    //   this.$refs[`infodrop_${this._uid}`]
    //     .querySelectorAll(".full-screen__trigger")
    //     .forEach(function (i) {
    //       i.addEventListener("click", function (event) {
    //         if (this.parentElement.querySelector("img")) {
    //           vm.$emit(
    //             "openImage",
    //             this.parentElement.querySelector("img").getAttribute("src")
    //           );
    //         } else if (this.parentElement.querySelector("video")) {
    //           vm.$emit(
    //             "openImage",
    //             this.parentElement.querySelector("video").getAttribute("src")
    //           );
    //         }
    //       });
    //     });
    // }

    if (
      this.$refs[`infodrop_${this._uid}`] &&
      this.$refs[`infodrop_${this._uid}`].querySelector(".video__trigger")
    ) {
      this.$refs[`infodrop_${this._uid}`]
        .querySelectorAll(".video__trigger")
        .forEach(function (i) {
          i.addEventListener("click", function (event) {
            vm.$emit(
              "openImage",
              this.parentElement.querySelector("img").getAttribute("video-src")
            );
          });
        });
    }
    if (
      this.$refs[`infodrop_${this._uid}`] &&
      this.$refs[`infodrop_${this._uid}`].querySelector(
        ".simple-carousel__container"
      )
    ) {
      document
        .querySelectorAll(".simple-carousel__container:not(.loaded)")
        .forEach((slider) => {
          let ComponentClass = Vue.extend(SimpleCarousel);
          let instance = new ComponentClass({
            propsData: {
              Slides: Array.from(slider.children),
              color: vm.color,
              autoplay: slider.getAttribute("data-autoplay"),
            },
          });
          instance.$on("openImage", (e) => vm.$emit("openImage", e));
          instance.$mount(); // pass nothing
          slider.innerHTML = "";
          slider.appendChild(instance.$el);
          slider.classList.add("loaded");
        });
    }
    if (
      this.$refs[`infodrop_${this._uid}`] &&
      this.$refs[`infodrop_${this._uid}`].querySelector(".test-block")
    ) {
      this.$refs[`infodrop_${this._uid}`]
        .querySelectorAll(".test-block")
        .forEach(async function (item) {
          const id = item.getAttribute("id");
          let comp = await require(`@/components/interactiveTests/${id}.vue`);
          if (!comp) return;
          let ComponentClass = Vue.extend(comp.default);
          let instance = new ComponentClass({});
          instance.$mount(); // pass nothing
          item.innerHTML = "";
          item.appendChild(instance.$el);
        });
    }
    if (
      this.$refs[`infodrop_${this._uid}`] &&
      this.$refs[`infodrop_${this._uid}`].querySelector(".timecodes")
    ) {
      const video = this.$refs[`infodrop_${this._uid}`].querySelector("video");
      this.$refs[`infodrop_${this._uid}`]
        .querySelectorAll(".timecodes__time")
        .forEach(async function (item) {
          item.addEventListener("click", function () {
            let time = this.innerText.split(":");
            let hours = parseInt(time[0].padStart(2, 0));
            let minutes = parseInt(time[1].padStart(2, 0));
            let seconds = parseInt(time[2].padStart(2, 0));
            let result = hours * 60 * 60 + minutes * 60 + seconds;
            video.currentTime = result;
            video.scrollIntoView({
              behavior: "smooth",
            });
          });
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.info-item__above {
  margin-top: 40px;
  &-title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 42px;
    line-height: 48px;
    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}
.info-item {
  padding-bottom: 0;
  transition: linear .1s;
  border-bottom: 1px solid rgba(66, 71, 83, 0.3);
  &__inner {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: linear 0.1s;
  }
  &__head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: auto;
    margin-top: 32px;
    padding-bottom: 24px;
    cursor: pointer;
    &-title {
      width: 95%;
      display: flex;
      align-items: center;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #3c4242;
      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
      span {
        margin-left: 8px;
        padding: 4px 8px;
        background: #3c4242;
        border-radius: 99px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: #ffffff;
        @media screen and (max-width: 767px) {
          margin-left: 0;
          margin-bottom: 8px;
        }
      }
    }
    &-arrow {
      width: 18px;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: linear 0.3s;
      cursor: pointer;
    }
  }
  &.active {
    padding-bottom: 16px;
    .info-item {
      &__inner {
        max-height: 100%;
        opacity: 1;
        overflow: visible;
      }

      &__head-arrow {
        transform: rotate(180deg);
      }
    }
  }
  video {
    width: 100%;
    height: auto;
  }
}
</style>

<style>
.simple-carousel__container {
  width: 100%;
  max-width: 800px;
}

.info-item__inner a,
.info-item__above a {
  line-break: anywhere;
}
</style>
